import React, { useEffect, useState } from 'react';
import {CourseCatalogueProps} from "./CourseCatalogue.props";
import ChangeWorkSpace from "../../hoc/changeWorkSpace";
import {Box} from "@mui/material";
import { IDataFilter, ITotalDataFilter, SearchMenuBar } from '../../components/SearchMenuBar';
import {CourseCatalogueCard} from "../../components/CourseCatalogueCard";
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getCourseCatalogueEvents,
  getCourseCatalogueEventsDataSelector, GetCourseCatalogueEventsParams,
  ICourseCatalogueData, removeCourseCatalogueEventData
} from '../../redux/courseCatalogueSlice';
import {useAppSelector} from "../../redux/hooks";
import {useDispatch} from "react-redux";
import './CourseCatalogue.css';
import { courseCategoriesDataSelector, getCourseCategoriesEvent } from '../../redux/courseCategoriesSlice';
import { LoadingPage } from '../../components/LoadingPage';

const PER_PAGE = 10;
const COURSE_STATUSES = [
  "Passed",
  "Outstanding",
  "Assigned"
];
const DEFAULT_PARAMS = {
  per_pages: PER_PAGE,
  page: 1,
  keyword: '',
  category: [],
  course_status: COURSE_STATUSES
};

const DEFAULT_SELECTED_ITEMS = {
  'Course Category': [],
  'Course Status': []
};

const CourseCatalogueComponent: React.FC<CourseCatalogueProps> = () => {
  const [filterSearchKeyword, setFilterSearchKeyword] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const { courseCatalogueData, isLoading } = useAppSelector(getCourseCatalogueEventsDataSelector);
  const { courseCategories, isLoading: categoriesLoading } = useAppSelector(courseCategoriesDataSelector);
  const [catalogueItems, setCatalogueItems] = React.useState<ICourseCatalogueData[]>([]);
  const [page, setPage] = useState(1);
  const [mainParams, setMainParams] = useState<any>(DEFAULT_PARAMS);
  const [selectedItems, setSelectedItems] = useState<any>(DEFAULT_SELECTED_ITEMS);
  const dispatch = useDispatch<any>();

  const courseStatusesFilterData: IDataFilter[] = COURSE_STATUSES.map((status, index) => ({
    id: index,
    content: status
  }));

  const totalDataFilter : ITotalDataFilter[] = [
    {
      key: 'Course Status',
      data: courseStatusesFilterData,
      isDisable: false
    },
    {
      key: 'Course Category',
      data: courseCategories,
      isDisable: courseCategories.length < 1
    }
  ];

  const getCatalogue = async (params: GetCourseCatalogueEventsParams) => {
    setMainParams(params);
    const data = await dispatch(getCourseCatalogueEvents(params));
    setCatalogueItems(data.payload?.list);
    setHasMore(true)
    setPage(1)
  };

  const getCategoriesAndCatalogue = async () => {
    const result = await dispatch(getCourseCategoriesEvent());
    if (result && result.payload?.length > 0) {
      const params = {
        ...DEFAULT_PARAMS,
        category: result.payload,
        course_status: COURSE_STATUSES
      };
      setMainParams(params);
      const data = await dispatch(getCourseCatalogueEvents(params));
      setCatalogueItems(data.payload?.list);
    }
  };

  const removeCatalogueData = async ()=>{
    await dispatch(removeCourseCatalogueEventData());
    setCatalogueItems([]);
  }
  useEffect(() => {
    getCategoriesAndCatalogue();
    return  () => {
      removeCatalogueData();
    };
  }, [])

  useEffect(() => {
    const { start_item } = courseCatalogueData;
    if (start_item > PER_PAGE) {
      setPage((start_item - 1) / PER_PAGE + 1)
    }
  }, [courseCatalogueData]);

  const resetFilters = () => {
    setSelectedItems(DEFAULT_SELECTED_ITEMS);
    setFilterSearchKeyword('');
    const params = {
      ...mainParams,
      keyword: '',
      page: 1,
      category: courseCategories.map(category => category.content),
      course_status: COURSE_STATUSES
    };
    getCatalogue(params);
  };

  const onSearchInboxByKeywords = (event: any) => {
    if (event.keyCode === 13) {
      const params = {
        ...mainParams,
        keyword: event.target.value,
        page: 1
      }
      getCatalogue(params);
    }
  };

  const handleFilter = (item: any, type: any) => {
    setSelectedItems((prev: any) => {
      return ({
        ...prev,
        [type]: item
      });
    });
    const selectedItem = {
      ...selectedItems,
      [type]: item
    }
    if(selectedItem[type] && selectedItem[type].length === 0) {
      if(type === 'Course Category'){
        selectedItem[type] = courseCategories.map((category) => category.content);
      }
      if (type === 'Course Status') {
        selectedItem[type] = COURSE_STATUSES;
      }
    }
    const params = {
      ...mainParams,
      page: 1,
      category: (type === 'Course Category') ? selectedItem['Course Category'] : mainParams.category,
      course_status: (type === 'Course Status') ? selectedItem['Course Status'] : mainParams.course_status
    }
    getCatalogue(params);
  };

  const handleClearFilter = (type: any) => {
    setSelectedItems((prev: any) => {
      return {
        ...prev,
        [type]: []
      };
    });
    const selectedItem = {
      ...selectedItems,
      [type]: []
    }
    if (selectedItem[type] && selectedItem[type].length ===0) {
      if (type === 'Course Category'){
        selectedItem[type] = courseCategories.map(item => item.content);
      }
      if (type === 'Course Status'){
        selectedItem[type] = COURSE_STATUSES;
      }
    }
    const params ={
      ...mainParams,
      category: (type === 'Course Category') ? selectedItem['Course Category'] : mainParams.category,
      course_status: (type === 'Course Status') ? selectedItem['Course Status'] : mainParams.course_status,
      page: 1
    }
    getCatalogue(params);
  };

  const fetchMoreData = async () => {
    const { total_page } = courseCatalogueData;
    if (total_page > page) {
      const data: any = await dispatch(getCourseCatalogueEvents({
        ...mainParams,
        page: page + 1,
      }));
      const { payload } = data;
      const item: any = payload?.list ? payload.list : []
      const items = [...catalogueItems,...item]
      setCatalogueItems(items)
    }
    setHasMore(total_page > page)
  };

  return (
    <>
      <h2 className={'course-catalogue-title'} data-testid='course-result-title-test'></h2>
      <Box
        className="catalogue-container"
        id="catalogue-container-id"
        sx={{
          background: 'white',
          boxShadow: '0 0 5px #d5d5d5'

        }}
      >
          <SearchMenuBar
            selectedItems={selectedItems}
            resetFilter={resetFilters}
            handleEnterSearch={onSearchInboxByKeywords}
            onFilter={handleFilter}
            searchInputValue={filterSearchKeyword}
            setSearchInputValue={setFilterSearchKeyword}
            onClearFilter={handleClearFilter}
            totalDataFilter={totalDataFilter}
          />
          <InfiniteScroll
            className={'catalogue-infinite-scroll'}
            dataLength={catalogueItems?.length || 0}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<></>}
            height={713}
          >
            {catalogueItems?.map(({
              campaign_id,
              course_name,
              course_category,
              end_date,
              tp_thumbnail,
              tp_id,
              status
            }, index) =>
              <CourseCatalogueCard
                key={`${tp_id} + ${index}`}
                title={course_name}
                subTitle={course_category}
                date={end_date}
                id={campaign_id}
                tp_id={tp_id}
                thumbnailPath={tp_thumbnail}
                status={status}
              />
            )}

          </InfiniteScroll>
      </Box>
      <LoadingPage open={isLoading || categoriesLoading} />
    </>
  );
}
export const CourseCatalogue = ChangeWorkSpace(CourseCatalogueComponent);