import React, { useState } from 'react';
import {useTheme, useMediaQuery, Stack, AlertColor } from '@mui/material';
import { LoginProps } from './Login.props';
import {Amplify, Auth} from 'aws-amplify';
import { images as Images } from '../../constants/images';
import { FieldControl } from '../../components/FieldControl';
import { SnackBar } from '../../components/SnackBar';
import './Login.css';
import './styles/ipad.css';
import './styles/mobile.css';
import {getLogin, getStatusLockUser } from '../../api/platform';
import { LoadingButton } from '@mui/lab';
import { KEY_PRESS, LOGIN_MESSAGES } from '../../constants/constants';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
// import { websocketActions } from '../../redux/websocketSlice';
import { useAppSelector } from '../../redux/hooks';
import { notificationsDataSelector } from '../../redux/notificationsSlice';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { updateLockLearnerEvents } from '../../redux/updateLockLearner';
import {AMPLIFY_CONFIG} from "../../configs/aws-exports";

export interface IConfigLockUser {
  attempt_number: number;
  window_session: number;
  lock_session: number;
  email: string;
  status: string;
  user_id: string | undefined;
}
export const LoginPage: React.FC<LoginProps> = () => {
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [step, setStep] = useState(0);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [userLogin, setUserLogin] = useState(null);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [severitySnackbar, setSeveritySnackbar] = useState<AlertColor>('error');
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [configClockUserData, setConfigClockUserData] = useState<IConfigLockUser>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const dispatch = useDispatch<any>();
  const { firebaseToken } = useAppSelector(notificationsDataSelector);
  const { t } = useTranslation();

  function isEmail(email: string) {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    if (!regEmail.test(email)) {
      return false;
    }
    return true;
  }
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClose = () => {
    setIsOpenSnackbar(false);
  };
  const handleEmail = (e: any) => {
    let value = e.target.value;
    if(value) {
      value = value.trim();
    }
    setEmail(value);
  };
  const handleCode = (e: any) => {
    let value = e.target.value;
    if(value) {
      value = value.trim();
    }
    setCode(value);
  };

  const checkClientId=(providerType:string)=>{
    let clientId;
    switch (providerType){
      case 'okta':
        clientId = process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID_OKTA;
        break;
      case 'azure':
        clientId = process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID_AZURE;
        break;
      default:
        clientId = process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID;
        break;
    }
    localStorage.setItem('client_id', clientId||'')
  }

  const onNext = async () => {
    if (!email) {
      setMessageSnackbar(t(LOGIN_MESSAGES.EMAIL_NOT_INPUT));
      setIsOpenSnackbar(true);
      return;
    }
    if (email && !isEmail(email)) {
      setMessageSnackbar(t(LOGIN_MESSAGES.EMAIL_INCORRECT));
      setIsOpenSnackbar(true);
      return;
    }
    try {
      setIsLoadingBtn(true);
      const result = await getLogin(email.toLowerCase());
      const emailLock = LOGIN_MESSAGES.EMAIL_LOCKED(result.remaining_time)
      const emailExpired = LOGIN_MESSAGES.EMAIL_EXPIRED(result.company_name)
      const statusMessages = [
        LOGIN_MESSAGES.NOT_EXISTS_COGNITO,
        LOGIN_MESSAGES.EMAIL_DISABLED,
        emailLock,
        emailExpired,
        LOGIN_MESSAGES.EMAIL_NOT_ENABLE_MODULE,
        LOGIN_MESSAGES.NOT_EXIST_IN_PH2,
        LOGIN_MESSAGES.OTP_HAS_EXPIRED,
      ];
      if (result && result.email) {
        if (
          result.status >= 0 &&
          result.status !== 1 &&
          result.status < statusMessages.length + 1
        ) {
          const messageIndex = result.status - Number(Boolean(result.status));
          setMessageSnackbar(t(statusMessages[messageIndex]));
          setIsOpenSnackbar(true);
          setIsLoadingBtn(false);
          return;
        }
        if(result && result.sso_status) {
          if(result && result.provider_type ){
              checkClientId(result.provider_type)
            }
          localStorage.setItem('provider_name', result.provider_name);
          await handleToSSO();
        } else {
          await handleToVerificationCode()
        }
        setIsOpenSnackbar(false);
        setIsLoadingBtn(false);
        localStorage.setItem('userEmail', result.email.toLowerCase());
        setConfigClockUserData(result);
     } else {
        setMessageSnackbar(t(LOGIN_MESSAGES.EMAIL_NOT_REGISTERED));
        setIsOpenSnackbar(true);
        setIsLoadingBtn(false);
      }
    } catch (e) {
      setIsLoadingBtn(false);
    }
  };

  const removeParamsAutoLock = () => {
    localStorage.removeItem('startTime');
    localStorage.removeItem('prevEmail');
    localStorage.removeItem('attempts');
  };

  const onVerify = async () => {
    let statusLock;
    try {
      setIsLoadingBtn(true);
      statusLock = await getStatusLockUser(email.toLowerCase());
      if (statusLock && statusLock?.is_lock) {
        setSeveritySnackbar('error');
        setMessageSnackbar(t(LOGIN_MESSAGES.EMAIL_LOCKED(statusLock.remaining_time)));
        setIsOpenSnackbar(true);
        setIsLoadingBtn(false);
        return;
      }
      if (statusLock && statusLock?.is_otp_expired) {
        setSeveritySnackbar("warning");
        setMessageSnackbar(t(LOGIN_MESSAGES.OTP_HAS_EXPIRED));
        setIsOpenSnackbar(true);
        setIsLoadingBtn(false);
        return;
      }
      if (code && email) {
        const verifyOTP = await Auth.sendCustomChallengeAnswer(userLogin, code);
        if (verifyOTP.signInUserSession && verifyOTP.signInUserSession.idToken !== '') {
          // dispatch(websocketActions.startConnecting());
          const data = { email };
          if (firebaseToken.length > 0) {
            Object.assign(data, { firebaseToken });
          }
          // dispatch(websocketActions.submitMessage(JSON.stringify(data)));
          setMessageSnackbar('');
          setIsOpenSnackbar(true);
          setIsLoadingBtn(false);
          removeParamsAutoLock();
          localStorage.setItem('isAuth', 'true')
        } else {
          const isLock = await autoLockUser(configClockUserData?.attempt_number, configClockUserData?.window_session);
          if(!isLock){
            setSeveritySnackbar('error');
            setMessageSnackbar(t(LOGIN_MESSAGES.PASSWORD_INCORRECT));
            setIsOpenSnackbar(true);
            setIsLoadingBtn(false);
          }
        }
      } else {
        const isLock = await autoLockUser(configClockUserData?.attempt_number, configClockUserData?.window_session);
        if(!isLock){
          setSeveritySnackbar('error');
          setMessageSnackbar(t(LOGIN_MESSAGES.PASSWORD_INCORRECT));
          setIsOpenSnackbar(true);
          setIsLoadingBtn(false);
        }
      }
    } catch (e: any) {
      setIsLoadingBtn(false);
    }
  };

  const keyPress = (e: any, type: string) => {
    if (e.keyCode === 13) {
      type === KEY_PRESS.NEXT ? onNext() : onVerify();
    }
  };

  const currentSecondsTime = () => {
    const seconds = moment();
    localStorage.setItem('startTime', seconds.toString());
  };
  const autoLockUser = async (numberOfAttempt = 3, observWindows = 1800) => {
    let attempts = localStorage.getItem('attempts') ? localStorage.getItem('attempts') : 1;
    const currentEmail = localStorage.getItem('userEmail');
    const startSecondTime = localStorage.getItem('startTime');
    const currentTime: any = moment.duration(moment().diff(moment(startSecondTime)));
    const prevLocalEmail = localStorage.getItem('prevEmail')
      ? localStorage.getItem('prevEmail')
      : email.toLowerCase();
    if (
      Number(attempts) >= numberOfAttempt &&
      currentEmail === prevLocalEmail &&
      currentTime._milliseconds <= observWindows * 1000
    ) {
      setSeveritySnackbar('error');
      const res = await dispatch(updateLockLearnerEvents(email.toLowerCase()));
      if (res && res.payload && res.payload.status) {
        setMessageSnackbar(t(LOGIN_MESSAGES.EMAIL_LOCKED(res.payload.remaining_time)));
        setIsOpenSnackbar(true);
        localStorage.setItem('attempts', '1');
        setIsLoadingBtn(false);
        return true;
      }
      setIsLoadingBtn(false);
    } else if (
      Number(attempts) >= numberOfAttempt ||
      currentEmail !== prevLocalEmail ||
      currentTime._milliseconds > observWindows * 1000
    ) {
      currentSecondsTime();
      localStorage.setItem('attempts', '2');
      localStorage.setItem('prevEmail', email.toLowerCase());
    } else {
      attempts = (Number(attempts) + 1).toString();
      localStorage.setItem('attempts', attempts);
      localStorage.setItem('prevEmail', email.toLowerCase());
    }
    if(attempts === 1) {
      currentSecondsTime()
    }
    return false;
  };

  const handleToVerificationCode = async () => {
    try{
    setIsLoadingBtn(true);
    const clientIdOTP= process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID;
    AMPLIFY_CONFIG.Auth['userPoolWebClientId'] = process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID;
    localStorage.setItem('client_id', clientIdOTP || '')
    await Amplify.configure(AMPLIFY_CONFIG);
    const user = await Auth.signIn(email.toLowerCase())
    setUserLogin(user);
    setStep((prev) => prev + 1);
    setIsLoadingBtn(false);
    } catch (e:any){
      setIsLoadingBtn(true);
    }
  }
  const handleToSSO = async () => {
    setIsLoadingBtn(true);
    const clientId = localStorage.getItem('client_id') || '';
    const providerName = localStorage.getItem('provider_name') || '';
    AMPLIFY_CONFIG.Auth['userPoolWebClientId'] = clientId;
    await Amplify.configure(AMPLIFY_CONFIG);
    await Auth.federatedSignIn({ customProvider: providerName});
  }

  const renderLoginSteps = () => {
    if (step === 0) {
      return (
        <Stack spacing={isMobile ? 3.75 : 8.75}>
          <FieldControl
            label="Email Address"
            type="text"
            name="emailAddress"
            value={email}
            placeholder="Enter Email Address"
            onChange={handleEmail}
            onKeyDown={(e: any) => keyPress(e, KEY_PRESS.NEXT)}
          />
          <LoadingButton
            onClick={onNext}
            className={'btn-login'}
            loading={isLoadingBtn}
            loadingPosition="end"
            variant="contained"
            tabIndex={-1}
            endIcon={<></>}
          >
            <span>Next</span>
          </LoadingButton>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={isMobile ? 3.75 : 6.125}>
          <FieldControl
            label="Phriendly Phishing Verification Code"
            type={showPassword ? 'text' : 'password'}
            name="oneTimePassword"
            placeholder="Enter Code"
            onChange={handleCode}
            value={code}
            onKeyDown={(e: any) => keyPress(e, KEY_PRESS.VERIFY)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword()}
                >
                  {showPassword ? (
                    <Visibility sx={{ color: '#B3B8B8' }} />
                  ) : (
                    <VisibilityOff sx={{ color: '#B3B8B8' }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
            helperText="The Phriendly Phishing verification code has been sent to your email."
          />
          <LoadingButton
            onClick={onVerify}
            className={'btn-login'}
            loading={isLoadingBtn}
            loadingPosition="end"
            variant="contained"
            tabIndex={-1}
            endIcon={<></>}
          >
            <span>Verify</span>
          </LoadingButton>
        </Stack>
      );
    }
  };
  return (
    <div className={'login-page'}>
      <div className={'login-header'}>
        <img className={'header-img'} src={Images.PhriendlyPhishing} alt="Brand of Learner Hub" />
      </div>
      <div className={'login-body'}>
        <Stack className="body-content">
          <Stack className="login-logo">
            <img className="logo__img" src={Images.Branding} alt="Logo of Learner Hub" />
          </Stack>
          {renderLoginSteps()}
        </Stack>
      </div>
      {messageSnackbar && (
        <SnackBar
          open={isOpenSnackbar}
          severity={severitySnackbar}
          message={messageSnackbar}
          onClose={() => handleClose()}
        />
      )}
    </div>
  );
};
