import React, { useEffect, useState } from 'react';
import { GradesProps } from './Grades.props';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { LoadingPage } from '../../components/LoadingPage';
import './Grades.css';
import './styles/mobile.css';
import './styles/ipad.css';
import { Box, Button, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getCertificateEvents, getCertificateEventsDataSelector } from '../../redux/getCertificateSlice';
import { GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import {ITotalDataFilter, SearchMenuBar} from '../../components/SearchMenuBar';
import {
  CourseGrades,
  getCourseCategoriesEvent,
  gradeCourseResultsEvents,
  gradeCourseResultsEventsDataSelector,
  GradeStatus
} from '../../redux/getListGradeCourseResultsSlice';
import { DataTables } from '../../components/DataTables';
import { CustomPagination } from '../../components/DataTables/components/CustomPagination';
import { School } from '@mui/icons-material';
import { BasicCell, MobileDataCell, MobileDataTable } from '../../components/MobileDataTable';
import CourseAssigned from '../../assets/images/course-assigned.svg';
import CourseFailed from '../../assets/images/course-failed.svg';
import CourseOutstanding from '../../assets/images/course-outstanding.svg';
import CoursePassed from '../../assets/images/course-passed.svg';
import { getCertHandler } from '../../utils/util';
import ChangeWorkSpace from "../../hoc/changeWorkSpace";

const defaultSortModel: GridSortModel = [{ field: 'course_name', sort: 'asc' }];
const defaultSelectedItems = {
  'Course Category': []
};

const GradesComponent: React.FC<GradesProps> = () => {
  const [courseResults, setCourseResults] = useState<any>([]);
  const dispatch = useDispatch<any>();
  const { listGradeCourseResults, isLoading, courseCategories =[], isLoadingCategories } = useAppSelector(
    gradeCourseResultsEventsDataSelector
  );
  const { isLoading: certificateLoading } = useAppSelector(
    getCertificateEventsDataSelector
  );
  const [selectedItems, setSelectedItems] = useState<any>(defaultSelectedItems);
  const [filterSearchKeyword, setFilterSearchKeyword] = useState('');
  const [totalPage, setTotalPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isTablet = useMediaQuery(theme.breakpoints.between('mobile', 'tablet'));
  const isLaptop = useMediaQuery(theme.breakpoints.between('tablet', 'laptop'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'));

  const defaultParams ={
    per_pages: 10,
    page: 1,
    keyword: filterSearchKeyword,
    category: [],
    sort_type: 'asc',
    sort_column: 'course_name'
  }
  const [mainParams, setMainParams] = useState<any>(defaultParams);

  const totalDataFilter : ITotalDataFilter[] = [
    {
      key: 'Course Category',
      data: courseCategories,
      isDisable: courseCategories.length < 1
    }
  ];

  const statusIcon = {
    Assigned: CourseAssigned,
    Failed: CourseFailed,
    Outstanding: CourseOutstanding,
    Passed: CoursePassed
  };

  const columns: GridColDef[] = [
    {
      field: 'course_name',
      headerName: 'Course Name',
      flex: isDesktop ? 3 : 4,
      headerClassName: 'data-table-header-cell',
      renderCell: (params: any) => {
        return <div className={'grade-cell'}>{params.value}</div>;
      }
    },
    {
      field: 'course_category',
      headerName: 'Course Category',
      flex: isDesktop ? 3 : 4,
      sortable: true,
      renderCell: (params: any) => {
        return <div className={'grade-cell'}>{params.value}</div>;
      }
    },
    {
      field: 'grade',
      headerName: 'Grade',
      sortable: true,
      flex: 2,
      renderCell: (params: any) => {
        return <div className={'grade-cell'}>{Number.isFinite(params.value) ? params.value + '%' : 'N/A'}</div>;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      flex: isDesktop ? 1 : isLaptop ? 2 : 3,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip
          title={params.value}
          placement='right-start'
          classes={{ popper: 'status-tooltip' }}
          className='status-icon'
          PopperProps={{
            sx:{marginLeft:'-15px !important', marginTop:'-11px !important'}
          }}
        >
          <img src={statusIcon[params.value as GradeStatus]} />
        </Tooltip>
      )
    },
    {
      field: '',
      headerName: 'Action',
      flex: isDesktop ? 2 : isLaptop ? 4 : 3,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction={isTablet ? 'column' : 'row'} spacing={1} margin='10px 0'>
            <Button
              variant={'outlined'}
              data-testid={'btn-overview-test'}
              className={'btn-course-result-general btn-overview-course-result'}
              onClick={() => navigate(`/courses/overview/${params.row.campaign_id}`,{
                state: {
                  tabIndex: 2,
                  subTabIndex:0
                }
              })}>
              Overview
            </Button>
            <Button
              variant={'contained'}
              data-testid={'btn-certificate-test'}
              disabled={!params.row['has_certificate']}
              className={'btn-course-result-general btn-cert-course-result'}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                getCertHandler(dispatch, getCertificateEvents, params.row.phish_id, event);
              }}
            >
              Certificate
            </Button>
          </Stack>
        );
      }
    }
  ];

  const getGradesResults = async (params:any) => {
    setMainParams(params);
    await dispatch(
      gradeCourseResultsEvents(params)
    );

  };

  const getCourseCategories =async ()=>{
    const result = await dispatch(getCourseCategoriesEvent());
    if(result && result.payload?.length > 0){
      const params = {
        ...defaultParams,
        category: result.payload
      }
      await getGradesResults(params);
    } else {
      await getGradesResults(defaultParams);
    }
  }

  useEffect(() => {
    getCourseCategories()
  }, [dispatch]);

  useEffect(() => {
    const data = listGradeCourseResults.list.map((result, index) => ({
      ...result,
      id: index
    }));
    setCourseResults(data);
    setTotalPage(listGradeCourseResults['total_page']);
    setTotalRows(listGradeCourseResults['total_items']);
    setStartPage(listGradeCourseResults['start_item']);
    setEndPage(listGradeCourseResults['end_item']);
  }, [listGradeCourseResults]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const model = newSortModel.length > 0 ? newSortModel : defaultSortModel;
    const params = {
      ...mainParams,
      sort_type: model[0].sort,
      sort_column: model[0].field
    }
    getGradesResults(params);
  }
  const handleClearFilter = (type: any) => {
    setSelectedItems((prev: any) => {
      return {
        ...prev,
        [type]: []
      };
    });
    const selectedItem = {
      ...selectedItems,
      [type]: []
    }
    if(selectedItem[type] && selectedItem[type].length ===0) {
      if(type === 'Course Category'){
        selectedItem[type] = courseCategories.map(item => item.content)
      }
    }
    const params ={
      ...mainParams,
      category: selectedItem['Course Category'],
      page: 1
    }
    setCurrentPage(1);
    getGradesResults(params);
  };

  const resetFilters = () => {
    setSelectedItems(defaultSelectedItems);
    setFilterSearchKeyword('');
    const params ={
      ...mainParams,
      keyword:'',
      page: 1,
      category: courseCategories.map(item => item.content)
    }
    setCurrentPage(1);
    getGradesResults(params);

  };
  const handleFilter = (item: any, type: any) => {
    setSelectedItems((prev: any) => {
      return ({
        ...prev,
        [type]: item
      });
    });
    const selectedItem = {
      ...selectedItems,
      [type]: item
    }
    if(selectedItem[type] && selectedItem[type].length ===0) {
      if(type === 'Course Category'){
        selectedItem[type] = courseCategories.map(item => item.content)
      }
    }
    const params ={
      ...mainParams,
      page: 1,
      category: selectedItem['Course Category']
    }
    setCurrentPage(1);
    getGradesResults(params);
  };
  const onSearchInboxByKeywords = (event: any) => {
    if (event.keyCode === 13) {
      const params ={
        ...mainParams,
        keyword: event.target.value,
        page: 1
      }
      setCurrentPage(1);
      getGradesResults(params);
    }
  };
  const onRowsPerPageChange=(perPage:number)=>{
      const params = {
        ...mainParams,
        per_pages: perPage,
        page: 1
      }
      getGradesResults(params);
  }
  const onChangePage=(currentPage:number)=>{
      const params = {
        ...mainParams,
        page:currentPage,
      }
      getGradesResults(params);
  }

  const mobileCells: MobileDataCell<CourseGrades>[] = [
    (params: CourseGrades) => (
      <Stack
        textAlign='center'
        borderBottom='1px solid #e6e7e7'
        paddingBottom={2.5}
        key={params.id}
      >
        <Typography fontSize={18}>{params.course_name}</Typography>
        <Typography fontSize={14}>{params.course_category}</Typography>
      </Stack>
    ),
    (params: CourseGrades) => <BasicCell key={ 'cell'+params.id} cellName="Grade" cellContent={params.grade ? params.grade + '%' : 'N/A'} />,
    (params: CourseGrades) => (
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className='mobile-basic-cell'
        paddingRight={0.25}
        key={'Status'+params.id}
      >
        <Typography fontSize={12} fontWeight={600}>
          Status
        </Typography>
        <img
          src={statusIcon[params.status as GradeStatus]}
          height={32}
          width={32}
        />
      </Stack>
    ),
    (params: CourseGrades) => (
      <Stack
        spacing={0.5}
        className='mobile-actions-cell'
        key={'mobile-actions-cell'+params.id}
      >
        <Button
          variant={'outlined'}
          data-testid={'btn-overview-mobile-test'}
          className="btn-overview-course-result"
          onClick={() => navigate(`/courses/overview/${params.campaign_id}`)}
        >
          Overview
        </Button>
        <Button
          variant={'contained'}
          data-testid={'btn-cert-mobile-test'}
          className="btn-cert-course-result"
          disabled={Boolean(params.has_cert)}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            getCertHandler(dispatch, getCertificateEvents, params.phish_id, event);
          }}
        >
          Certificate
        </Button>
      </Stack>
    )
  ];

  return (
    <>
      <h2 className={'course-result-title'} data-testid='course-result-title-test'>Results</h2>
      <Box
        className="data-container"
        id="grades-data-container"
        sx={{
          background: 'white',
          padding: '20px'
        }}
      >
        <SearchMenuBar
          selectedItems={selectedItems}
          resetFilter={resetFilters}
          handleEnterSearch={onSearchInboxByKeywords}
          onFilter={handleFilter}
          searchInputValue={filterSearchKeyword}
          setSearchInputValue={setFilterSearchKeyword}
          onClearFilter={handleClearFilter}
          totalDataFilter={totalDataFilter}
          mobileDrawerIcon={<School />}
          mobileDrawerTitle="Grades"
        />
        {isMobile ? (
          <MobileDataTable<CourseGrades> data={courseResults} cells={mobileCells} />
        ) : (
          <DataTables
            tblRows={courseResults}
            tblColumns={columns}
            pageSize={5}
            sortingMode='server'
            sortModelChangeHandler={handleSortModelChange}
            customPaging={
              <CustomPagination
                rowsPerPageOptions={[5, 10, 20]}
                currentPage={currentPage}
                totalPage={totalPage}
                perPage={perPage}
                startPage={startPage}
                endPage={endPage}
                totalRows={totalRows}
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
                onChangePage = {onChangePage}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            }
            totalRows={totalRows}
          />
        )}
      </Box>
      <LoadingPage open={isLoading || isLoadingCategories ||certificateLoading} />
    </>
  );
};
export const Grades = ChangeWorkSpace(GradesComponent);